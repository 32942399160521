import { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
import NewsList from './NewsList';

const MemberArea = () => {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // Nouvel état pour la case à cocher
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Vérifier si les termes sont acceptés
    if (!termsAccepted) {
      setError("Vous devez accepter les conditions d’utilisation pour vous connecter.");
      setLoading(false);
      return;
    }

    const result = await login(email, password);

    if (!result.success) {
      setError(result.message);
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <Container className="mt-4">
        <h2>Connexion requise</h2>
        <p>Vous devez être connecté pour accéder à l’espace membre.</p>

        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Entrez votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  J’ai lu et j’accepte les{" "}
                  <a
                    href="https://ogog.ch/uploads/Conditions_utilisation_OGOG.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#007bff" }}
                  >
                    conditions d’utilisation
                  </a>.
                </>
              }
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
          </Form.Group>

          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? "Connexion..." : "Se connecter"}
          </Button>
        </Form>
      </Container>
    );
  }

  // Vérifier les rôles
  const allowedRoles = ["user", "editor", "admin"];
  if (!allowedRoles.includes(user.role)) {
    return (
      <Container className="mt-4">
        <h2>Accès refusé</h2>
        <p>Votre compte n'a pas accès à cette section.</p>
        <Button variant="secondary" onClick={logout}>Déconnexion</Button>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <Button variant="secondary" onClick={logout}>Déconnexion</Button>
      {user.role === "admin" && (
        <Button 
          variant="primary" 
          className="ms-3" 
          onClick={() => navigate("/admin")}
        >
          Admin
        </Button>
      )}
      <br />
      <br />
      <p>
        Les données et informations contenues dans l’espace membre sont strictement personnelles, confidentielles et protégées par le secret conformément au droit suisse, en particulier la Loi fédérale sur la protection des données (LPD). Elles sont exclusivement réservées aux membres de l’OGOG. Toute publication, utilisation ou diffusion, même partielle, de ces données sans autorisation préalable et expresse constitue une violation grave des obligations légales et peut engager la responsabilité civile et pénale de son auteur, notamment au titre des articles 179bis et suivants du Code pénal suisse relatifs à la violation du secret ou à l’obtention illicite de données personnelles. Si vous n’êtes pas membre de l’OGOG, vous n’êtes pas autorisé à accéder à cet espace membre et êtes tenu de ne pas lire, copier, utiliser ou divulguer son contenu sous peine de poursuites. Nous vous prions instamment de signaler immédiatement cette erreur à l’administrateur de l’espace membre et de quitter sans délai cette section, tout en procédant à la suppression irréversible de tout accès ou copie de ces données de votre système. Toute infraction à ces obligations sera considérée comme intentionnelle et pourra faire l’objet de mesures juridiques appropriées.
      </p>
      <br />
      <NewsList isPublic={false} limit={10} />
    </Container>
  );
};

export default MemberArea;