import React from "react";
import { Outlet } from "react-router";
import MyNavbar from "./MyNavbar";
import MyFooter from "./MyFooter";
import { Container } from "react-bootstrap";

const Layout = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <MyNavbar />
      <Container className="flex-grow-1 mt-4">
        <Outlet /> {/* Contenu dynamique des pages */}
      </Container>
      <MyFooter />
    </div>
  );
};

export default Layout;
