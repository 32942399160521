import React, { useState } from "react";
import { Button, Modal, Card } from "react-bootstrap";
import ProfilePage from "./ProfilePage";

const MyCard = ({ member }) => {
  const [showProfile, setShowProfile] = useState(false);

  // Déterminer Dr ou Dre selon gender_id (1 pour masculin, 2 pour féminin)
  const titlePrefix = member.gender_id === 1 ? "Dr" : "Dre";

  // Déterminer l'image à afficher
  const profileImage = member.profile_picture 
    ? member.profile_picture 
    : (member.gender_id === 1 ? "./img/members/male.png" : "./img/members/female.png");

  // Déterminer l'icône à afficher en fonction des permissions
  const getPermissionIcon = () => {
    if (!member.permissions || member.permissions.length === 0) return null;

    const perm = member.permissions[0]; // Prendre la première permission
    if (perm.permission_state === "Autorisation octroyée" && perm.activity_state === "Actif") {
      return <i className="bi bi-patch-check-fill text-primary ms-2"></i>;
    } else if (perm.permission_state === "Autorisation octroyée" && perm.activity_state === "Inactif") {
      return <i className="bi bi-patch-check text-secondary ms-2"></i>;
    } else if (perm.permission_state === "Pas d'autorisation, autorisation retirée" && perm.activity_state === "Inactif") {
      return <i className="bi bi-ban text-danger ms-2"></i>;
    }
    return null; // Pas d'icône si aucune condition n'est remplie
  };

  // Récupérer la première adresse ou un texte par défaut si aucune adresse
  const firstAddress = member.addresses && member.addresses.length > 0 
    ? member.addresses[0] 
    : null;

  return (
    <>
      <Card style={{ width: "18rem", margin: "10px" }}>
        <Card.Img 
          variant="top" 
          src={profileImage} 
          alt={`${member.first_name} ${member.name}`}
          style={{ height: "300px", objectFit: "cover" }} // Hauteur fixe, proportions préservées
        />
        <Card.Body>
          <Card.Title>
            {titlePrefix} {member.first_name} {member.name}
            {getPermissionIcon()}
          </Card.Title>
          <Card.Text>
            {firstAddress ? (
              <>
                {firstAddress.street_with_number}<br />
                {firstAddress.zip} {firstAddress.city}
              </>
            ) : (
              "Adresse non disponible"
            )}
          </Card.Text>
          <Button variant="primary" onClick={() => setShowProfile(true)}>
            Voir Profil
          </Button>
        </Card.Body>
      </Card>

      {/* Modale avec le profil */}
      <Modal show={showProfile} onHide={() => setShowProfile(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {titlePrefix} {member.first_name} {member.name}
            {getPermissionIcon()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProfilePage memberData={member} closeModal={() => setShowProfile(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyCard;