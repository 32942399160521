import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [biography, setBiography] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [cliniques, setCliniques] = useState([]);
  const [selectedCliniques, setSelectedCliniques] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [website, setWebsite] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('./backend/reset/reset.php?action=get_data');
        if (!response.ok) throw new Error('Erreur réseau');
        const data = await response.json();
        if (data.success) {
          setCliniques(data.data.cliniques || []);
          setLanguages(data.data.languages || []);
        } else {
          setError(data.message || 'Erreur lors du chargement des données');
        }
      } catch (err) {
        setError('Erreur lors du chargement des données : ' + err.message);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleCliniqueChange = (cliniqueId) => {
    setSelectedCliniques((prev) =>
      prev.includes(cliniqueId) ? prev.filter((id) => id !== cliniqueId) : [...prev, cliniqueId]
    );
  };

  const handleLanguageChange = (languageId) => {
    setSelectedLanguages((prev) =>
      prev.includes(languageId) ? prev.filter((id) => id !== languageId) : [...prev, languageId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!email || !password) {
      setError('Email et mot de passe sont requis');
      return;
    }

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    if (biography !== '') formData.append('biography', biography); // Seulement si rempli
    if (contactEmail !== '') formData.append('contact_email', contactEmail); // Seulement si rempli
    if (profilePicture) formData.append('profile_picture', profilePicture); // Seulement si fourni
    formData.append('cliniques', JSON.stringify(selectedCliniques));
    formData.append('languages', JSON.stringify(selectedLanguages));
    if (website !== '') formData.append('website', website); // Seulement si rempli

    try {
      const response = await fetch('./backend/reset/reset.php', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Erreur réseau');
      const data = await response.json();

      if (data.success) {
        setMessage('Profil mis à jour avec succès');
        setEmail('');
        setPassword('');
        setBiography('');
        setContactEmail('');
        setProfilePicture(null);
        setSelectedCliniques([]);
        setSelectedLanguages([]);
        setWebsite('');
      } else {
        setError(data.message || 'Erreur lors de la mise à jour');
      }
    } catch (err) {
      setError('Erreur de connexion au serveur : ' + err.message);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Mise à jour du profil</Card.Title>
              {message && <Alert variant="success">{message}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email AMGE: prenom.nom@amge.ch</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Text style={{ color: 'red' }}>
                    Tu dois mettre cet identifiant même si tu n'as pas d'adresse amge.ch. Pour moi par exemple, c'est jean.locher@amge.ch
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Définir un mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Text style={{ color: 'red' }}>
                    Choisis un mot de passe et ne l'oublie pas.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="biography">
                  <Form.Label>Biographie : le texte que tu souhaites voir figurer à l'onglet Biographie de ton profil</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={biography}
                    onChange={(e) => setBiography(e.target.value)}
                    // Pas de required ici
                  />
                  <Form.Text style={{ color: 'red' }}>
                    C'est ton CV. Un texte où tu mets tout ce que tu veux sur toi. Si tu as ton propre site, mets-y ton lien.
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="website">
                  <Form.Label>Site web</Form.Label>
                  <Form.Control
                    type="url"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="https://example.com"
                    // Pas de required ici
                  />
                  <Form.Text style={{ color: 'red' }}>
                    Entrez l’URL du site web (optionnel).
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="contactEmail">
                  <Form.Label>Email du cabinet :</Form.Label>
                  <Form.Control
                    type="email"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    // Pas de required ici
                  />
                  <Form.Text style={{ color: 'red' }}>
                    C'est l'adresse avec laquelle tu veux que le public communique avec toi.
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="profilePicture">
                  <Form.Label>Photo de profil</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    // Pas de required ici
                  />
                  <Form.Text style={{ color: 'red' }}>
                    Tu cliques sur « Choisir le fichier » et tu sélectionnes ta photo sur ton ordi. Pense à utiliser une image avec ton visage au centre pour qu'il apparaisse bien. L'image apparaitra carrée.
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="cliniques">
                  <Form.Label>Agréments cliniques</Form.Label>
                  {cliniques.length > 0 ? (
                    cliniques.map((clinique) => (
                      <Form.Check
                        key={clinique.id}
                        type="checkbox"
                        id={`clinique-${clinique.id}`}
                        label={clinique.name}
                        checked={selectedCliniques.includes(clinique.id)}
                        onChange={() => handleCliniqueChange(clinique.id)}
                      />
                    ))
                  ) : (
                    <p>Aucune clinique disponible</p>
                  )}
                  <Form.Text style={{ color: 'red' }}>
                    Coche les cliniques dans lesquelles tu travailles.
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="languages">
                  <Form.Label>Langues parlées</Form.Label>
                  {languages.length > 0 ? (
                    languages.map((language) => (
                      <Form.Check
                        key={language.id}
                        type="checkbox"
                        id={`language-${language.id}`}
                        label={language.name_fr}
                        checked={selectedLanguages.includes(language.id)}
                        onChange={() => handleLanguageChange(language.id)}
                      />
                    ))
                  ) : (
                    <p>Aucune langue disponible</p>
                  )}
                  <Form.Text style={{ color: 'red' }}>
                    Coche les langues que tu parles.
                  </Form.Text><br/>
                  <Form.Text className="text-muted">
                    Laisse vide pour ne pas modifier.
                  </Form.Text>
                  </Form.Group>

                {message && <Alert variant="success">{message}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}

                <Button variant="primary" type="submit">
                  Mettre à jour
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Reset;