import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const MyFooter = () => {
  return (
    <footer className="bg-secondary text-dark py-4">
        <Container>
            <Row className="text-center text-md-start">
            {/* Colonne des informations */}
            <Col md={4} className="mb-3">
                <h5>OGOG</h5>
                <p>Ordre des Gynécologues et Obstétriciens de Genève.</p>
                {/* <p><i className="bi bi-geo-alt-fill"></i> c/o Bd des Philosophes 9, 1205 Genève</p>
                <p><i className="bi bi-envelope-fill"></i> info@ogog.ch</p>
                <p><i className="bi bi-telephone-fill"></i> +41 22 329 01 46</p> */}
                <p>
                    <i className="bi bi-geo-alt-fill"></i>{" "}
                    <a 
                        href={navigator.userAgent.match(/(iPhone|iPad|Mac OS X)/i) 
                            ? "https://maps.apple.com/?address=Boulevard%20des%20Philosophes%209,%201205%20Gen%C3%A8ve,%20Suisse&auid=5757615337711106348&ll=46.197459,6.144401&lsp=9902&q=Dr%20m%C3%A9d.%20Locher%20Jean" 
                            : "https://www.google.com/maps/place/Dr+méd.+Locher+Jean/@46.197459,6.144401,18z/data=!3m1!4b1!4m6!3m5!1s0x478c7ad4b25081cd:0xf4cfe6b14ac0cfbd!8m2!3d46.197459!4d6.144401!16s%2Fg%2F1tmpjdw0?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-dark text-decoration-none"
                    >
                        c/o Bd des Philosophes 9, 1205 Genève
                    </a>
                </p>
                <p>
                    <i className="bi bi-envelope-fill"></i>{" "}
                    <a 
                        href="mailto:contact@ogog.ch" 
                        className="text-dark text-decoration-none"
                    >
                        info@ogog.ch
                    </a>
                </p>
                {/* <p>
                    <i className="bi bi-telephone-fill"></i>{" "}
                    <a 
                        href="tel:+41223290146" 
                        className="text-dark text-decoration-none"
                    >
                        +41 22 329 01 46
                    </a>
                </p> */}


            </Col>

            {/* Colonne des liens */}
            <Col md={4} className="mb-3">
                <h5>Liens utiles</h5>
                <ul className="list-unstyled">
                <li><a href="https://amge.ch" className="text-light text-decoration-none">AMGe association des médecins du canton de Genève</a></li>
                <li><a href="https://www.sggg.ch" className="text-light text-decoration-none">SGGG gynécologie suisse</a></li>
                <li><a href="https://www.fmh.ch" className="text-light text-decoration-none">FMH</a></li>  
                {/* <li><a href="https://www.siwf.ch/fr/formation-postgraduee/titres-specialiste-formations.cfm#i115955" className="text-light text-decoration-none">SIWF (anciennement FMH)</a></li>
                <li><a href="#about" className="text-light text-decoration-none">À propos</a></li>
                <li><a href="#members" className="text-light text-decoration-none">Membres</a></li>
                <li><a href="#contact" className="text-light text-decoration-none">Contact</a></li> */}
                </ul>
            </Col>

            {/* Colonne des réseaux sociaux */}
            <Col md={4} className="mb-3">
                <h5>Suivez-nous</h5>
                <a href="#" className="text-light me-3"><i className="bi bi-linkedin"></i></a>
                <a href="#" className="text-light me-3"><i className="bi bi-twitter-x"></i></a>

                <a href="#" className="text-light me-3"><i className="bi bi-instagram"></i></a>
                <a href="#" className="text-light me-3"><i class="bi bi-tiktok"></i></a>
                <a href="#" className="text-light me-3"><i className="bi bi-facebook"></i></a>

                <a href="#" className="text-light me-3"><i className="bi bi-youtube"></i></a>
                <a href="#" className="text-light me-3"><i class="bi bi-twitch"></i></a>
                <a href="#" className="text-light me-3"><i class="bi bi-vimeo"></i></a>

                <a href="#" className="text-light me-3"><i class="bi bi-telegram"></i></a>
                <a href="#" className="text-light me-3"><i class="bi bi-whatsapp"></i></a>
                <a href="#" className="text-light me-3"><i class="bi bi-wechat"></i></a>


            </Col>
            </Row>

            {/* Ligne de copyright */}
            <Row className="mt-3">
            <Col className="text-center">
                <p className="mb-0">&copy; {new Date().getFullYear()} OGOG. Tous droits réservés.</p>
                <a href="https://truewindspeed.ch" className="mb-0 text-decoration-none text-dark">Design: TrueWindSpeed Sàrl</a>
            </Col>
            </Row>
        </Container>
        </footer>

  );
};

export default MyFooter;
