import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { scroller } from "react-scroll";
import { Container } from "react-bootstrap";
import MyCarrousel from "../components/MyCarrousel";
import MyAccordion from "../components/MyAccordion";
import NewsList from "../components/NewsList";
import Directory from "./Directory";
import Comite from "../components/Comite";
import MemberArea from "../components/MemberArea";
import MyMap from "../components/MyMap";

const Home = () => {
  const location = useLocation();
  const [members, setMembers] = useState(() => {
    // Charger depuis sessionStorage si disponible
    const cached = sessionStorage.getItem("membersCache");
    return cached ? JSON.parse(cached) : [];
  });
  const [loading, setLoading] = useState(!sessionStorage.getItem("membersCache"));

  // Récupérer les données des membres depuis l'API
  useEffect(() => {
    const fetchMembers = async () => {
      if (members.length > 0) return; // Ne refait pas la requête si déjà en cache

      try {
        const response = await fetch("https://ogog.ch/backend/api/get_users.php");
        const data = await response.json();
        setMembers(data || []);
        sessionStorage.setItem("membersCache", JSON.stringify(data || [])); // Mise en cache
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des membres:", error);
        setLoading(false);
      }
    };

    fetchMembers();
  }, [members.length]);

  // Gestion du défilement
  useEffect(() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 50,
        smooth: "easeInOutSine",
      });
    }
  }, [location]);

  return (
    <Container>
      <section id="home">
        <MyCarrousel />
      </section>

      <section id="about" style={{ paddingTop: "120px", paddingBottom: "120px" }}>
        <h2>À propos de nous</h2>
        <MyAccordion />
      </section>

      <hr className="my-1" />

      <section id="news" style={{ paddingTop: "120px", paddingBottom: "120px" }}>
        <h2>Actualités</h2>
        {loading ? (
          <p>Chargement des actualités...</p>
        ) : (
          <NewsList isPublic={true} limit={10} />
        )}
      </section>

      <hr className="my-1" />

      <section id="members" style={{ paddingTop: "120px", paddingBottom: "120px" }}>
        <h2>Liste des membres actifs</h2>
        {loading ? (
          <p>Chargement des membres...</p>
        ) : (
          <Directory members={members} />
        )}
      </section>

      <section id="members" style={{ paddingTop: "60px", paddingBottom: "120px" }}>
        <h2>Carte des membres actifs</h2>
        {loading ? (
          <p>Chargement de la carte...</p>
        ) : (
          <MyMap members={members} />
        )}
      </section>

      <hr className="my-1" />

      <section id="comite" style={{ paddingTop: "120px", paddingBottom: "120px" }}>
        <h2>Comité</h2>
        {loading ? (
          <p>Chargement du comité...</p>
        ) : (
          <Comite members={members} />
        )}
      </section>

      <hr className="my-1" />

      <section id="members-area" style={{ paddingTop: "120px", paddingBottom: "120px" }}>
        <h2>Espace Membres</h2>
        <MemberArea />
      </section>
    </Container>
  );
};

export default Home;