// import React from "react";

// import MyAccordion from "./components/MyAccordion";
// import MyButton from "./components/MyButton";
// import MyCard from "./components/MyCard";
// import MyCarrousel from "./components/MyCarrousel";
// import MyNavbar from "./components/MyNavbar";

// import TestButton from "./components/testButton";

// // import Directory from './Directory';



// import { RouterProvider, createBrowserRouter } from "react-router";

// import MyCard from './components/MyCard';
// import ProfilePage from './components/ProfilePage';



// const App = () => {

//     const router = createBrowserRouter([
//         { path: "/", element: <MyCard member={{ ID: 1, FIRSTNAME: "John", NAME: "Doe", DOC_IMAGE: "/img/profile.jpg", CITY: "Paris", TITLE: "Médecin" }} /> },
//         { path: "/profile/:id", element: <ProfilePage /> },
//       ]);
//   return (
//     <div className="container mt-5">
//         <TestButton/>
//         <MyNavbar/>
//         <br/>
//         <MyCarrousel/>
//         <br/>
//         <MyButton/>
//         <br/>
//         <MyCard/> <MyCard/>
//         <MyCard/>
//         <MyCard/>
//         <br/>
//         <h2>À propos de nous</h2>
//         <MyAccordion/>
//         <br/>
//         <h1>Annuaire des Membres</h1>
//         <RouterProvider router={router} />
//         <br/><br/>
//         <br/><br/>
//         <br/><br/>
//         {/* <p>lalal</p>
//       <h1>Bienvenue sur mon app React Bootstrap</h1> */}
//       {/* <MyButton text="Clique-moi" onClick={() => alert("Bouton cliqué !")} /> */}
//     </div>
//   );
// };

// export default App;


import React from "react";
import { RouterProvider } from "react-router";
import { AuthProvider } from "./context/AuthContext";
import router from "./router"; // Importe le router

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
