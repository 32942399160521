import React, { useState, useEffect, useRef } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css"; // Style MapLibre
import { Modal, Button } from "react-bootstrap";
import ProfilePage from "./ProfilePage"; // Assurez-vous que ce composant existe

const MyMap = ({ members }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    if (!members || !mapContainer.current) return;

    // Filtrer les membres avec des adresses valides (latitude et longitude)
    const validMembers = members.filter(member =>
      member.addresses && member.addresses.some(addr => addr.latitude && addr.longitude)
    );
    if (validMembers.length === 0) return;

    if (!map.current) {
      // Centre initial sur la première adresse valide
      const firstValidAddress = validMembers[0].addresses.find(
        addr => addr.latitude && addr.longitude
      );
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
        // center: [parseFloat(firstValidAddress.longitude), parseFloat(firstValidAddress.latitude)],
        center: [6.1444255, 46.197458],
        zoom: 12, // Zoom initial plus large pour voir plusieurs membres
      });

      // Ajouter un marqueur pour chaque membre
      const bounds = new maplibregl.LngLatBounds();
      validMembers.forEach(member => {

        ////////////////
        // // const address = member.addresses[0]; // // Prendre uniquement la première adresse
        // // const lngLat = [parseFloat(address.longitude), parseFloat(address.latitude)];
        // // bounds.extend(lngLat);

        // // const marker = new maplibregl.Marker()
        // //   .setLngLat(lngLat)
        // //   .setPopup(
        // //     new maplibregl.Popup().setHTML(`
        // //       <h6>${member.first_name} ${member.name}</h6>
        // //       <p>${address.street_with_number || ""}<br>${address.zip || ""} ${address.city || ""}</p>
        // //       <button class="btn btn-sm btn-primary" id="profile-btn-${member.id}">
        // //         Voir le profil
        // //       </button>
        // //     `)
        // //   )
        // //   .addTo(map.current);

        // // // // Ajouter un événement au bouton dans le popup
        // // marker.getPopup().on('open', () => {
        // //   document.getElementById(`profile-btn-${member.id}`).addEventListener('click', () => {
        // //     setSelectedMember(member);
        // //     setShowProfile(true);
        // //   });
        // // });
        ////////////////

        member.addresses.forEach((address, index) => {
          if (address.latitude && address.longitude) {
            const lngLat = [parseFloat(address.longitude), parseFloat(address.latitude)];
            bounds.extend(lngLat);

            const marker = new maplibregl.Marker()
              .setLngLat(lngLat)
              .setPopup(
                new maplibregl.Popup().setHTML(`
                  <h6>${member.gender_id === 1 ? "Dr " : member.gender_id === 2 ? "Dre " : ""} ${member.first_name} ${member.name}</h6>
                  <p>${address.street_with_number || ""}<br>${address.zip || ""} ${address.city || ""}</p>
                  <button class="btn btn-sm btn-primary" id="profile-btn-${member.id}-${index}">
                    Voir le profil
                  </button>
                `)
              )
              .addTo(map.current);

            // Ajouter un événement au bouton dans le popup
            marker.getPopup().on('open', () => {
              document.getElementById(`profile-btn-${member.id}-${index}`).addEventListener('click', () => {
                setSelectedMember(member);
                setShowProfile(true);
              });
            });
          }
        });
      
      });

      // Ajuster la vue pour inclure tous les marqueurs
      // map.current.fitBounds(bounds, { padding: 50, maxZoom: 15 });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [members]);

  // Icône pour les permissions (adaptée de votre exemple)
  const getPermissionIcon = () => {
    if (selectedMember?.is_comite) {
      return <i className="bi bi-star-fill ms-2 text-warning" title="Membre du comité"></i>;
    }
    return null;
  };

  return (
    <>
      <div
        ref={mapContainer}
        style={{ height: "80vh", width: "100%", margin: "0", padding: "0", borderRadius: "10px" }}
      />

      {/* Modal de profil */}
      {selectedMember && (
        <Modal
          show={showProfile}
          onHide={() => setShowProfile(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedMember.gender_id === 1 ? "Dr " : selectedMember.gender_id === 2 ? "Dre " : ""}{selectedMember.first_name} {selectedMember.name}
              {getPermissionIcon()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProfilePage
              memberData={selectedMember}
              closeModal={() => setShowProfile(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default MyMap;