import React from "react";
import Carousel from "react-bootstrap/Carousel";

import slide1 from '../../img/carousel/2025-02-26 20.13.24.jpg';
import slide2 from '../../img/carousel/header_smn_gynaecology.png';
import slide3 from '../../img/carousel/csm_AdobeStock_499994525_hormone_verhuetung_9c76eeb2b9.jpg';
import slide4 from '../../img/carousel/header_smn_gynaecologic_examination-1.png';
import slide5 from '../../img/carousel/csm_slider_hf_unlincensed_familie_frauen_1bc43a4c7c.jpg';
import slide6 from '../../img/carousel/csm_slider_hf_unlicensed_1_kinderwunsch_2_0ebe1d1a38.jpg';
import slide7 from '../../img/carousel/csm_AdobeStock_582660259_pubertaet_970992431b.jpg';
import slide8 from '../../img/carousel/csm_AdobeStock_197638325_kinderwunsch_5735b16fd9.jpg';
import slide9 from '../../img/carousel/csm_AdobeStock_33273467_c032a1ec36.jpeg';

const slides = [
  {
    image: slide1,
    title: "Ordre des Gynécologues et Obstétriciens de Genève",
    description: "Les membres de l’OGOG sont les médecins détenteurs du diplôme de spécialiste FMH en gynécologie et obstétrique qui composent le groupe de spécialistes en gynécologie de l’Association des médecins de Genève (AMG)."
  },
  {
    image: slide2,
    title: "Qui sont les membres de l'Ordre des Gynécologues et Obstétriciens de Genève ?",
    description: "Les membres de l’OGOG forment le groupe cantonal de spécialistes en gynécologie de l’Association des Médecins de Genève."
  },
  {
    image: slide3,
    title: "Quelles sont les buts de cette association professionnelle ?",
    description: "Les membres de l’OGOG sont des médecins détenteurs du diplôme de spécialiste FMH en gynécologie et obstétrique, membres de l'Association des Médecins du Canton de Genève, inscrits au Registre de la profession du Canton de Genève, qui manifestent un intérêt pour les buts de l'Association."
  },
  {
    image: slide4,
    title: "Quel est l'organisation du système de santé ?",
    description: "La Fédération des médecins suisses (FMH), l’Association des médecins du canton de Genève (AMG), la société suisse de Gynécologie (SSGO): Gynécologie suisse, forment la pyramide organisationnelle de la gynécologie en Suisse. L’Ordre des Gynécologues et Obstétriciens de Genève dépend de cette organisation."
  },
  {
    image: slide5,
    title: "Pourquoi tous les Gynécologue ne font-ils pas partie de l'association ?",
    description: "Les membres de l’OGOG doivent faire partie des sociétés médicales qui règlementent et contrôlent la profession, et démontrer une formation et une intégration adéquate."
  },
  {
    image: slide6,
    title: "Le suivi de la grossesse",
    description: "Participer à la santé et la sécurité des futures mères et leur bébé, tout au long de leur grossesse et dans le post-partum. Permettre l'épanouissement du couple dans cette aventure de la vie."
  },
  {
    image: slide7,
    title: "Puberté et santé des adolescentes",
    description: "Prodiguer des soins respectueux, en adéquation avec ce passage difficile vers la vie d’adulte."
  },
  {
    image: slide8,
    title: "La fertilité et le désir d’enfant",
    description: "Avec empathie, compétence et haute technicité, permettre l'accomplissement d'un projet familial."
  },
  {
    image: slide9,
    title: "La santé des femmes",
    description: "Accompagner la santé de la femme, de l’adolescence jusqu'au grand âge, dans la prévention, l’accompagnement global ainsi que dans la prise en charge de pathologies complexes."
  }
];

function MyCarousel() {
  return (
    <Carousel>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={slide.image}
            alt={`Slide ${index + 1}`}
            style={{
              height: "500px",  // Définit la hauteur
              objectFit: "cover" // Remplissage sans déformation
            }}
          />
          <Carousel.Caption style={{ 
            color: 'white', // Couleur de texte contrastée (adaptez selon votre image)
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' // Ombre portée
          }}>
            <h3>{slide.title}</h3>
            <p>{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
    // {/* <Carousel>
    //   {slides.map((slide, index) => (
    //     <Carousel.Item key={index}>
    //       <img
    //         className="d-block w-100"
    //         src={slide.image}
    //         alt={`Slide ${index + 1}`}
    //         style={{
    //           height: "500px",  // Définit la hauteur
    //           objectFit: "cover" // Remplissage sans déformation
    //         }}
    //       />
    //       <Carousel.Caption style={{ 
    //         backgroundColor: 'rgba(0, 0, 0, 0.2)', // Arrière-plan semi-transparent noir
    //         padding: '20px', // Ajoute un peu d'espace autour du texte
    //         borderRadius: '5px' // Bords arrondis (facultatif)
    //       }}>
    //         <h3>{slide.title}</h3>
    //         <p>{slide.description}</p>
    //       </Carousel.Caption>
    //     </Carousel.Item>
    //   ))}
    // </Carousel> */}
  );
}

export default MyCarousel;
