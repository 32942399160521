// import React from "react";
// import { useParams } from "react-router";
// import { Container, Image } from "react-bootstrap";


// // Exemple de données d'actualités
// const newsData = [
//   {
//     id: 1,
//     title: "Nouvelle avancée en gynécologie",
//     image: "/img/news/news1.jpg",
//     content: "Une nouvelle technique révolutionnaire voit le jour...",
//   },
//   {
//     id: 2,
//     title: "Conférence annuelle 2024",
//     image: "/img/news/news2.jpg",
//     content: "Rejoignez-nous pour notre conférence sur la santé féminine...",
//   },
//   {
//     id: 3,
//     title: "Mise à jour des recommandations",
//     image: "/img/news/news3.jpg",
//     content: "Les nouvelles recommandations pour les patientes enceintes...",
//   },
// ];

// const NewsDetail = () => {
//   const { id } = useParams();
//   const news = newsData.find((n) => n.id === parseInt(id));



//   if (!news) {
//     return <p>Actualité introuvable.</p>;
//   }

//   return (
//     <Container className="mt-4">
//       <h2>{news.title}</h2>
//       <Image src={news.image} fluid rounded className="mb-3" />
//       <p>{news.content}</p>
//     </Container>
//   );
// };

// export default NewsDetail;




import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Image, Spinner } from "react-bootstrap";

const NewsDetail = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const response = await fetch(`https://ogog.ch/backend/api/get_news_detail.php?id=${id}`);
        const data = await response.json();
        
        if (data.success && data.news) {
          setNews(data.news);
        } else {
          console.error("Erreur serveur:", data.message);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'actualité:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetail();
  }, [id]);

  if (loading) {
    return (
      <Container className="mt-4 text-center">
        <Spinner animation="border" />
      </Container>
    );
  }

  if (!news) {
    return (
      <Container className="mt-4 text-center">
        <p>Actualité introuvable.</p>
      </Container>
    );
  }
  return (
    <Container className="mt-4" style={{ paddingTop: "40px" /* Ajustez selon la hauteur de la navbar */ }}>
      {/* Conteneur pour l'image de couverture */}
      {news.cover && (
        <div
          style={{
            height: "50vh", // Corrigé de "10wh" à "10vh" (10% de la hauteur de la viewport)
            width: "80%",
            overflow: "hidden",
            borderRadius: "8px",
            marginBottom: "1.5rem",
            marginLeft: "auto", // Centrage horizontal
            marginRight: "auto",
            display: "flex", // Pour centrer l'image si nécessaire
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={news.cover}
            alt={news.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Remplit le conteneur en conservant les proportions
              objectPosition: "center", // Centre l'image verticalement et horizontalement
            }}
          />
        </div>
      )}

      {/* Titre avec style amélioré */}
      <h2
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "1.5rem",
          lineHeight: "1.2",
          textAlign: "center", // Centrage du titre si désiré
        }}
      >
        {news.title}
      </h2>

      {/* Contenu avec HTML brut */}
      <div
        dangerouslySetInnerHTML={{ __html: news.content }}
        style={{
          fontSize: "1rem",
          lineHeight: "1.6",
          color: "#555",
          overflowX: "hidden",
        }}
      />
    </Container>
  );
};

export default NewsDetail;
