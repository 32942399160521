import { createBrowserRouter, useLocation, useNavigationType } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import ProfilePage from "./components/ProfilePage";
import AdminPanel from "./components/AdminPanel";
import NewsDetail from "./components/NewsDetail";
import MemberArea from "./components/MemberArea";
import Reset from "./components/Reset";
import Avatar from "./pages/Avatar";

// Composant pour gérer la restauration du défilement
const ScrollRestoration = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === "POP") {
      // Retour en arrière ou avant via le navigateur
      window.scrollTo(0, window.sessionStorage.getItem(`scrollPos_${location.pathname}`) || 0);
    }

    const handleScroll = () => {
      window.sessionStorage.setItem(`scrollPos_${location.pathname}`, window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location, navigationType]);

  return null;
};


const router = createBrowserRouter([
    {
        path: "/",
        // element: <Layout />,  // Layout gère la navbar/footer
        element: (
            <>
                <Layout />
                <ScrollRestoration /> {/* Ajout%C3%A9 ici */}
            </>
        ),
        children: [
            { index: true, element: <Home /> },  
            { path: "profile/:id", element: <ProfilePage /> },
            // { path: "admin", element: <AdminPanel /> },
            { path: "news/:id", element: <NewsDetail /> },
            { path: "members-area", element: <MemberArea /> }, 
        ],
    },
    { path: "admin", element: <AdminPanel /> },
    { path: "reset", element: <Reset /> },
    // { path: "AutoVulves", element: <AutoVulves /> }, 
    // { path: "Vulves", element: <Vulves /> }, 
    // { path: "Vulve", element: <Vulve /> }, 
    { path: "Avatar", element: <Avatar /> }, 
]);

export default router;
