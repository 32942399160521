import React from "react";
import { createRoot } from "react-dom/client";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css"; // ✅ Charge le CSS dans l'app React
// import 'maplibre-gl/dist/maplibre-gl.css';


import App from "./App"; // Import du composant principal


const root = createRoot(document.getElementById("root"));
root.render(<App />);

