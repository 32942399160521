import React from "react";
import { useState, useEffect } from "react";

import { Navbar, Nav, Container } from "react-bootstrap";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useNavigate, useLocation } from "react-router";
import logo from "../../img/logo.png";


const MyNavbar = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const [expanded, setExpanded] = useState(false);

  // Gérer le clic sur un lien
  const handleNavClick = (section) => {
    setExpanded(false); // 🔴 Ferme la navbar

    if (location.pathname === "/") {
      // ✅ Si déjà sur la home, scroll directement
      scroll.scrollTo(document.getElementById(section).offsetTop, {
        duration: 50,
        smooth: 'easeInOutSine', //'easeInOutQuad', //"easeInOutQuart", //true, //
      });
    } else {
      // ✅ Sinon, naviguer à la home et scroller après chargement
      navigate("/", { state: { scrollTo: section } });
    }
  };

  // Si la page se recharge sur la home avec un scroll à faire
  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = location.state.scrollTo;
      setTimeout(() => {
        scroll.scrollTo(document.getElementById(section).offsetTop, {
          duration: 50,
          smooth: 'easeInOutSine', //'easeInOutQuad', //"easeInOutQuart", //true, //
        });
      }, 100);
    }
  }, [location]);






  return (
    <Navbar expanded={expanded} onToggle={setExpanded} expand="lg" className="bg-body-tertiary" fixed="top">
      <Container>
        <Navbar.Brand onClick={() => handleNavClick("home")} className="d-flex align-items-center">
          <img
            alt="Logo OGOG"
            src={logo}
            width="60"
            height="60"
            className="d-inline-block align-middle me-2"
          />
          <span className="navbar-brand-text">
            <span className="d-none d-lg-inline">Ordre des Gynécologues et Obstétriciens de Genève</span>
            <span className="d-lg-none">Ordre des Gynécologues <br/>et Obstétriciens de Genève</span>
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => handleNavClick("home")}>Accueil</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("about")}>À propos</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("news")}>Actualité</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("members")}>Liste des membres actifs</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("comite")}>Comité</Nav.Link>
            <Nav.Link onClick={() => handleNavClick("members-area")}>Espace membres</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>



    // <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
    // <Container>
    //       <Navbar.Brand href="#home">
    //         <img
    //           alt=""
    //           src={logo}
    //           width="60"
    //           height="60"
    //           className="d-inline-block align-middle"
    //         />{' OGOG'}
            
    //       </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav className="me-auto">

    //         <Nav.Link href="#home">Accueil</Nav.Link>
    //         <Nav.Link href="#about">À propos</Nav.Link>
    //         <Nav.Link href="#news">Actualité</Nav.Link>
    //         <Nav.Link href="#members">Liste des membres</Nav.Link>
    //         <Nav.Link href="#members-area">Espace membres</Nav.Link>

    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
};

export default MyNavbar;
