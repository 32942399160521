

import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap'; // Importez le composant Button de React Bootstrap


const avatarOptions = {
  face: ["Hectic.svg", "Cyclops.svg"],
  facial_hair: ["Full.svg", "Chin.svg"],
  head: ["Afro.svg", "Buns.svg"],
  accessories: ["Eyepatch.svg", "Glasses.svg"],
};

function AvatarPreview({ avatar, onParameterChange }) {
  const getAvatarPartImage = (partName) => {
    const imageName = avatar[partName];
    return `/img/avatar/${partName}/${imageName}`;
  };

  const [selectedParameter, setSelectedParameter] = useState(Object.keys(avatarOptions)[0]); // Nouveau state

  const handleSwipe = (direction) => {
    const parameterIndex = Object.keys(avatarOptions).indexOf(selectedParameter);
    let nextParameterIndex;

    if (direction === 'up') {
      nextParameterIndex = (parameterIndex + 1) % Object.keys(avatarOptions).length;
    } else if (direction === 'down') {
      nextParameterIndex = (parameterIndex - 1 + Object.keys(avatarOptions).length) % Object.keys(avatarOptions).length;
    } else if (direction === 'left') {
        const currentIndex = avatarOptions[selectedParameter].indexOf(avatar[selectedParameter]);
        const nextIndex = (currentIndex + 1) % avatarOptions[selectedParameter].length;
        onParameterChange(selectedParameter, avatarOptions[selectedParameter][nextIndex]);
    } else if (direction === 'right') {
        const currentIndex = avatarOptions[selectedParameter].indexOf(avatar[selectedParameter]);
        const prevIndex = (currentIndex - 1 + avatarOptions[selectedParameter].length) % avatarOptions[selectedParameter].length;
        onParameterChange(selectedParameter, avatarOptions[selectedParameter][prevIndex]);
    }

    if (nextParameterIndex !== undefined) {
      setSelectedParameter(Object.keys(avatarOptions)[nextParameterIndex]);
    }
  };

  const [isSwiping, setIsSwiping] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);


  const handleTouchStart = (e) => {
    setIsSwiping(true);
    setStartX(e.touches[0].clientX);
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;
    const diffX = currentX - startX;
    const diffY = currentY - startY;

    const threshold = 50; // Adjust threshold for swipe sensitivity

    if (Math.abs(diffX) > threshold || Math.abs(diffY) > threshold) {
      setIsSwiping(false);
      if (Math.abs(diffX) > Math.abs(diffY)) {
          handleSwipe(diffX > 0 ? 'right' : 'left');
      } else {
          handleSwipe(diffY > 0 ? 'down' : 'up');
      }
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  const handleMouseDown = (e) => { // Mouse events for desktop
    setIsSwiping(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
  };

  const handleMouseMove = (e) => {
    if (!isSwiping) return;

    const currentX = e.clientX;
    const currentY = e.clientY;
    const diffX = currentX - startX;
    const diffY = currentY - startY;

    const threshold = 20;

    if (Math.abs(diffX) > threshold || Math.abs(diffY) > threshold) {
      setIsSwiping(false);
      if (Math.abs(diffX) > Math.abs(diffY)) {
          handleSwipe(diffX > 0 ? 'right' : 'left');
      } else {
          handleSwipe(diffY > 0 ? 'down' : 'up');
      }
    }
  };

  const handleMouseUp = () => {
    setIsSwiping(false);
  };



  const handleNextParameter = () => {
    const parameterIndex = Object.keys(avatarOptions).indexOf(selectedParameter);
    const nextParameterIndex = (parameterIndex + 1) % Object.keys(avatarOptions).length;
    setSelectedParameter(Object.keys(avatarOptions)[nextParameterIndex]);
  };


  return (
    <Container>
      <Row>
        <Col xs={12} className="d-flex justify-content-center"> {/* Centrer les images */}
          <div style={{ position: 'relative', width: '100vw', height: '100vw' }}> {/* Conteneur pour les images */}
            {Object.keys(avatarOptions).map(partName => (
              <div key={partName} style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <img
                  src={getAvatarPartImage(partName)}
                  alt={partName}
                  style={{ width: '100%', height: '100%', opacity: partName === selectedParameter ? 1 : 0.5, transition: 'opacity 0.3s', objectFit: 'contain' }}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row className="mt-3"> {/* Marge au-dessus du bouton */}
        <Col xs={12} className="d-flex justify-content-center"> {/* Centrer le bouton */}
          <Button variant="primary" onClick={handleNextParameter}>
            OK
          </Button>
        </Col>
      </Row>
    </Container>
  );
}


// ... (AvatarCreator component remains the same)


function AvatarCreator() {
  const [avatar, setAvatar] = useState(
    Object.keys(avatarOptions).reduce((acc, key) => {
      acc[key] = avatarOptions[key][0];
      return acc;
    }, {})
  );

  const handleParameterChange = (parameterName, newValue) => {
    setAvatar({ ...avatar, [parameterName]: newValue });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AvatarPreview avatar={avatar} onParameterChange={handleParameterChange} /> {/* Transmission de la fonction handleParameterChange */}
    </div>
  );
}

export default AvatarCreator;
