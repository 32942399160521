// import React from "react";
// import { Card, Button, Row, Col, Container } from "react-bootstrap";
// import { useNavigate } from "react-router";



// const newsData = [
//   {
//     id: 1,
//     title: "Nouvelle avancée en gynécologie",
//     image: "/img/news/news1.png",
//     summary: "Une nouvelle technique révolutionnaire voit le jour...",
//   },
//   {
//     id: 2,
//     title: "Conférence annuelle 2024",
//     image: "/img/news/news2.jpg",
//     summary: "Rejoignez-nous pour notre conférence sur la santé féminine...",
//   },
//   {
//     id: 3,
//     title: "Mise à jour des recommandations",
//     image: "/img/news/news3.jpg",
//     summary: "Les nouvelles recommandations pour les patientes enceintes...",
//   },
// ];

// const NewsList = () => {
//   const navigate = useNavigate();

//   return (
//     // <Container className="mt-4">
//     //   {/* <h2>Actualités</h2> */}
//     //   <Row>
//     //     {newsData.map((news) => (
//     //       <Col key={news.id} md={4} className="mb-4">
//     //         <Card>
//     //           <Card.Img variant="top" src={news.image} />
//     //           <Card.Body>
//     //             <Card.Title>{news.title}</Card.Title>
//     //             <Card.Text>{news.summary}</Card.Text>
//     //             <Button variant="primary" onClick={() => navigate(`/news/${news.id}`)}>
//     //               Lire plus
//     //             </Button>
//     //           </Card.Body>
//     //         </Card>
//     //       </Col>
//     //     ))}
//     //   </Row>
//     // </Container>
//     <Container className="mt-4">
//         <Row>
//             {newsData.map((news) => (
//             <Col key={news.id} md={4} className="mb-4">
//                 <Card>
//                 <div className="ratio ratio-16x9"> {/* Utilise ratio-4x3 pour 4:3 */}
//                     <Card.Img variant="top" src={news.image} className="object-fit-cover" />
//                 </div>
//                 <Card.Body>
//                     <Card.Title>{news.title}</Card.Title>
//                     <Card.Text>{news.summary}</Card.Text>
//                     <Button variant="primary" onClick={() => navigate(`/news/${news.id}`)}>
//                     Lire plus
//                     </Button>
//                 </Card.Body>
//                 </Card>
//             </Col>
//             ))}
//         </Row>
//     </Container>

//   );
// };

// export default NewsList;




import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";

const NewsList = ({ isPublic, limit = 6 }) => { // Ajout du paramètre limit, avec valeur par défaut 6
    const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const cardHeight = 400; // Hauteur fixe des cartes
  const previewLines = 3; // Nombre de lignes de preview à afficher

  const truncateText = (text, lines) => {
    const lineHeight = 1.5; // Ajustez selon votre CSS
    const maxHeight = lines * lineHeight + 'em'; // Utilisation de em pour la taille relative

    return (
      <div style={{ 
        maxHeight: maxHeight, 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        display: '-webkit-box', 
        '-webkit-line-clamp': lines, 
        '-webkit-box-orient': 'vertical' 
      }}>
        {text}
      </div>
    );
  };


  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
            `./backend/api/get_news.php?is_public=${isPublic ? 1 : 0}&limit=${limit}` // Paramètre limit dans l'URL
          );
        const data = await response.json();
        if (data.success) {
          setNewsData(data.news);
        } else {
          console.error("Erreur serveur:", data.message);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des actualités:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  return (
    <Container className="mt-4">
      {/* <h2 className="mb-4">Actualités</h2> */}
      
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : newsData.length > 0 ? (
        <Row>
          {newsData.map((news) => (
            <Col key={news.id} md={4} className="mb-4">
              {/* <Card> */}
              <Card style={{ height: '100%' }}> {/* Ajout de style pour la hauteur */}

                <div className="ratio ratio-16x9">
                  <Card.Img
                    variant="top"
                    // src={news.cover ? `/uploads/${news.cover}` : "/img/default.jpg"}
                    src={news.cover ? `${news.cover}` : "/img/default.jpg"}
                    className="object-fit-cover"
                  />
                </div>
                <Card.Body>
                  <Card.Title>{news.title}</Card.Title>
                  {/* <Card.Text>
                    {news.contentPreview}
                  </Card.Text> */}
                  <Card.Text style={{ flexGrow: 1 }}> {/* flex-grow pour occuper l'espace */}
                    {truncateText(news.contentPreview, previewLines)}
                  </Card.Text>
                  <Button variant="primary" onClick={() => navigate(`/news/${news.id}`)}>
                    Lire plus
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p className="text-center">Aucune actualité disponible.</p>
      )}
    </Container>
  );
};

export default NewsList;
