import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Comite = ({ members }) => {
  // Filtrer les membres selon leur rôle dans le comité
  const president = members.find(member => member.is_comite === 100);
  const tresorier = members.find(member => member.is_comite === 10);
  const membres = members.filter(member => member.is_comite === 1);

  // Fonction pour déterminer le titre (Dr ou Dre)
  const getTitlePrefix = (genderId) => (genderId === 1 ? "Dr" : "Dre");

  // Fonction pour obtenir l'image de profil ou une image par défaut
  const getProfileImage = (member) => {
    return member.profile_picture 
      ? member.profile_picture 
      : (member.gender_id === 1 ? "./img/members/male.png" : "./img/members/female.png");
  };

  // Fonction pour déterminer le titre du rôle en fonction du genre
  const getRoleTitle = (role, genderId) => {
    if (role === "Président") {
      return genderId === 1 ? "Président" : "Présidente";
    } else if (role === "Trésorier") {
      return genderId === 1 ? "Trésorier" : "Trésorière";
    }
    return role; // Pour "Membre", pas de changement
  };

  // Rendu d'un membre du comité
  const renderMember = (member, role) => (
    <div className="text-center">
      <img
        src={getProfileImage(member)}
        alt={`${member.first_name} ${member.name}`}
        className="bd-placeholder-img rounded-circle"
        width="140"
        height="140"
        style={{ objectFit: "cover" }}
      />
      <h2 className="fw-normal mt-3">
        {getTitlePrefix(member.gender_id)} {member.first_name} {member.name}
      </h2>
      <p>
        {member.addresses && member.addresses.length > 0 ? (
          <>
            {member.addresses[0].street_with_number}<br />
            {member.addresses[0].zip} {member.addresses[0].city}
          </>
        ) : (
          "Adresse non disponible"
        )}
      </p>
    </div>
  );

  return (
    <Container className="my-5">
      <h1 className="text-center mb-5">Le Comité</h1>

      {/* Président et Trésorier côte à côte avec titres distincts */}
      {(president || tresorier) && (
        <Row className="justify-content-center mb-5">
          {president && (
            <Col lg={4} md={6} className="text-center">
              <h3 className="mb-4">{getRoleTitle("Président", president.gender_id)}</h3>
              {renderMember(president, "Président")}
            </Col>
          )}
          {tresorier && (
            <Col lg={4} md={6} className="text-center">
              <h3 className="mb-4">{getRoleTitle("Trésorier", tresorier.gender_id)}</h3>
              {renderMember(tresorier, "Trésorier")}
            </Col>
          )}
        </Row>
      )}

      {/* Membres */}
      {membres.length > 0 && (
        <>
          <h3 className="text-center mb-4">Membres</h3>
          <Row className="justify-content-center">
            {membres.map((membre, index) => (
              <Col key={index} lg={4} md={6} className="text-center mb-4">
                {renderMember(membre, "Membre")}
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default Comite;