import React, { useEffect, useState } from "react";
import MyCard from "../components/MyCard";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function Directory({ members }) {
  const [filteredMembers, setFilteredMembers] = useState(members);
  const [specialties, setSpecialties] = useState([]);
  const [subSpecialties, setSubSpecialties] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [allCliniques, setAllCliniques] = useState([]);
  const [cliniques, setCliniques] = useState([]);
  const [location, setLocation] = useState(null);
  const [useLocation, setUseLocation] = useState(false);

  const [filters, setFilters] = useState({
    search: "",
    specialty: "Gynécologie et obstétrique",
    language: "",
  });

  const [selectedSubSpecialties, setSelectedSubSpecialties] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [selectedCliniques, setSelectedCliniques] = useState([]);

  // Charger les filtres depuis l'API
  useEffect(() => {
    fetch("https://ogog.ch/backend/api/get_filters.php")
      .then(response => response.json())
      .then(data => {
        setSpecialties(data.specialties || []);
        setSubSpecialties(data.sub_specialties || []);
        setCertifications(data.certifications || []);
        setLanguages(data.languages || []);
        setAllCliniques(data.cliniques || []);
      })
      .catch(error => console.error("Erreur lors du chargement des filtres:", error));
  }, []);

  // Mettre à jour les cliniques en fonction de la spécialité sélectionnée
  useEffect(() => {
    if (filters.specialty) {
      setCliniques(
        allCliniques.filter(c => c.specialties && c.specialties.includes(filters.specialty))
      );
    } else {
      setCliniques(allCliniques);
    }
  }, [filters.specialty, allCliniques]);

  // Mettre à jour les membres filtrés en fonction des filtres
  useEffect(() => {
    let url = new URL("./backend/api/get_users.php", window.location.origin);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) url.searchParams.append(key, value);
    });
    if (selectedSubSpecialties.length) {
      url.searchParams.append("sub_specialties", selectedSubSpecialties.join(","));
    }
    if (selectedCertifications.length) {
      url.searchParams.append("certifications", selectedCertifications.join(","));
    }
    if (selectedCliniques.length) {
      url.searchParams.append("cliniques", selectedCliniques.join(","));
    }
    if (useLocation && location) {
      url.searchParams.append("lat", location.lat);
      url.searchParams.append("lng", location.lng);
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setFilteredMembers(data || []);
      })
      .catch(error => {
        console.error("Erreur lors du chargement des membres filtrés:", error);
        setFilteredMembers(members); // Revenir aux membres non filtrés en cas d'erreur
      });
  }, [filters, selectedSubSpecialties, selectedCertifications, selectedCliniques, location, useLocation, members]);

  const handleGetLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setUseLocation(true);
      },
      error => {
        console.error("Impossible de récupérer la localisation", error);
        alert("Impossible d'accéder à votre position.");
      }
    );
  };

  const handleCheckboxChange = (value, stateUpdater) => {
    stateUpdater(prev =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
  };

  return (
    <Container fluid>
      <Form className="p-3">
        <Row className="align-items-center">
          <Col md={4} className="mb-1">
            <Form.Control
              type="text"
              placeholder="Rechercher un nom"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </Col>
          <Col md={4} className="mb-2">
            <Form.Select
              value={filters.language}
              onChange={(e) => setFilters({ ...filters, language: e.target.value })}
            >
              <option value="">Toutes les langues</option>
              {languages.map(l => (
                <option key={l.id} value={l.name_fr}>{l.name_fr}</option>
              ))}
            </Form.Select>
          </Col>
          <Col md={4} className="d-flex gap-3">
            <Button
              variant="secondary"
              onClick={() => {
                setFilters({ search: "", specialty: "", language: "" });
                setSelectedSubSpecialties([]);
                setSelectedCertifications([]);
                setSelectedCliniques([]);
                setUseLocation(false);
              }}
            >
              Réinitialiser
            </Button>
            <Button variant="primary" onClick={handleGetLocation} disabled={useLocation}>
              <i className="bi bi-geo-fill"></i> Trier par proximité
            </Button>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col md={4} className="mt-3">
            <h6>Sous-spécialités :</h6>
            {subSpecialties
              .filter(s => !filters.specialty || s.specialty_id === specialties.find(sp => sp.name_fr === filters.specialty)?.id)
              .map(s => (
                <Form.Check
                  key={s.id}
                  type="checkbox"
                  label={s.name_fr}
                  checked={selectedSubSpecialties.includes(s.name_fr)}
                  onChange={() => handleCheckboxChange(s.name_fr, setSelectedSubSpecialties)}
                />
              ))}
          </Col>
          <Col md={4} className="mt-3">
            <h6>Certifications :</h6>
            {certifications.map(c => (
              <Form.Check
                key={c.id}
                type="checkbox"
                label={c.name_fr}
                checked={selectedCertifications.includes(c.name_fr)}
                onChange={() => handleCheckboxChange(c.name_fr, setSelectedCertifications)}
              />
            ))}
          </Col>
          <Col md={4} className="mt-3">
            <h6>Agréments Cliniques :</h6>
            {cliniques.map(c => (
              <Form.Check
                key={c.id}
                type="checkbox"
                label={c.name}
                checked={selectedCliniques.includes(c.name)}
                onChange={() => handleCheckboxChange(c.name, setSelectedCliniques)}
              />
            ))}
          </Col>
        </Row>
      </Form>

      <Row className="justify-content-center">
        {filteredMembers.length > 0 ? (
          filteredMembers.map(member => (
            <Col key={member.id} xs={12} sm={6} md={4} lg={3}>
              <MyCard member={member} />
            </Col>
          ))
        ) : (
          <p>Aucun membre trouvé.</p>
        )}
      </Row>
    </Container>
  );
}

export default Directory;