import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Container, Form, Button, Image } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";

function AdminPanel() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [contentPreview, setContentPreview] = useState("");
  const [cover, setCover] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [isPublic, setIsPublic] = useState(false);

  const MAX_PREVIEW_LENGTH = 200;

  if (!user || user.role !== "admin") {
    navigate("/members-area");
    return null;
  }

  const handleContentPreviewChange = (e) => {
    const text = e.target.value;
    if (text.length <= MAX_PREVIEW_LENGTH) {
      setContentPreview(text);
    } else {
      setContentPreview(text.substring(0, MAX_PREVIEW_LENGTH));
    }
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCover(file);
      setCoverPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let coverUrl = null;

    if (cover) {
      const formData = new FormData();
      formData.append("file", cover);

      try {
        const uploadResponse = await fetch("./backend/admin/upload.php", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        const uploadText = await uploadResponse.text();
        console.log("Réponse brute du serveur (upload):", uploadText);

        const uploadData = JSON.parse(uploadText);
        if (!uploadData.success) {
          alert("Erreur lors de l'upload de l'image de couverture: " + (uploadData.message || "inconnue"));
          return;
        }
        coverUrl = uploadData.location;
      } catch (error) {
        console.error("Erreur lors de l'upload de l'image de couverture:", error);
        alert("Erreur réseau ou parsing lors de l'upload de l'image de couverture.");
        return;
      }
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("contentPreview", contentPreview);
    formData.append("content", content);
    formData.append("is_public", isPublic ? 1 : 0);
    if (coverUrl) {
      formData.append("cover_url", coverUrl);
    }

    try {
      const response = await fetch("./backend/admin/add_news.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      const text = await response.text();
      console.log("Réponse brute du serveur (ajout article):", text);

      const data = JSON.parse(text);
      if (data.success) {
        alert("Article ajouté avec succès !");
        setTitle("");
        setContentPreview("");
        setContent("");
        setCover(null);
        setCoverPreview(null);
        setIsPublic(false);
      } else {
        alert(`Erreur serveur (ajout article): ${data.message}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'article:", error);
      alert("Une erreur s'est produite lors de l'ajout de l'article.");
    }
  };

  return (
    <Container className="mt-5 pb-5">
      <div className="mt-5 pb-5" />
      <h2>Ajouter une Actualité</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Titre</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Texte de l'aperçu</Form.Label>
          <Form.Control
            type="text"
            value={contentPreview}
            onChange={handleContentPreviewChange}
            maxLength={MAX_PREVIEW_LENGTH}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Contenu</Form.Label>
          <Editor
            apiKey="q6gcgkouncj5tqjx3e3iw6seieccdff40xo80l2t6zbqyorv"
            value={content}
            init={{
              height: 400,
              convert_urls: false,
              menubar: true,
              plugins: "image link lists code",
              toolbar: "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code",
              images_upload_url: "./backend/admin/tiny_mce_upload.php",
              image_class_list: [
                { title: "Responsive", value: "img-responsive" }
              ],
              image_dimensions: true, // Activer les dimensions personnaliséess
              images_upload_url: "./backend/admin/tiny_mce_upload.php",
              file_picker_types: 'file image', // Autoriser fichiers et images
              file_picker_callback: (callback, value, meta) => {
                if (meta.filetype === 'file' || meta.filetype === 'image') {
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*,.pdf'); // Accepter .avif et .pdf
                  input.onchange = function () {
                    const file = this.files[0];
                    const formData = new FormData();
                    formData.append('file', file);

                    fetch('./backend/admin/tiny_mce_upload.php', {
                      method: 'POST',
                      body: formData,
                      credentials: 'include',
                    })
                      .then(response => response.json())
                      .then(data => {
                        if (data.success) {
                          callback(data.location, { title: file.name });
                        } else {
                          alert('Erreur lors de l\'upload: ' + data.message);
                        }
                      })
                      .catch(error => console.error('Erreur upload:', error));
                  };
                  input.click();
                }
              }
            }}
            onEditorChange={setContent}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Image de couverture</Form.Label>
          <div>
            <input
              type="file"
              accept="image/*,.avif" // Ajout de .avif
              className="d-none"
              id="cover-upload"
              onChange={handleCoverChange}
            />
            <Button
              variant="outline-primary"
              onClick={() => document.getElementById("cover-upload").click()}
            >
              Upload Image
            </Button>
            {coverPreview && (
              <div className="mt-3">
                <Image
                  src={coverPreview}
                  alt="Aperçu de l'image de couverture"
                  fluid
                  style={{ maxHeight: "200px", borderRadius: "10px" }}
                />
              </div>
            )}
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Rendre cette actualité publique"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Publier
        </Button>
      </Form>
    </Container>
  );
}

export default AdminPanel;